
import { defineComponent, ref, onMounted, computed, onBeforeMount } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import CreateStudentModal from "../../../components/modals/wizards/CreateStudentModal.vue";
import router from "@/router/clean";

interface IStudent {
  studentId: number;
  fullName: string;
  email: string;
  accountType: string;
  dob: string;
  contract_number: string;
}

export default defineComponent({
  name: "students-listing",
  components: {
    Datatable,
    CreateStudentModal,
  },
  setup() {
    const store = useStore();
    const checkedStudents = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "",
        key: "picture",
        sortable: false,
      },
      {
        name: "Student id",
        key: "studentId",
        sortable: true,
      },
      {
        name: "Name",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Account type",
        key: "accountType",
        sortable: true,
      },
      {
        name: "Birthday",
        key: "dob",
        sortable: true,
      },
      {
        name: "State",
        key: "state",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const search = ref<string>("");

    const students = computed(() => store.state.StudentModule.students);

    const displayedStudents = computed(() =>
      students.value.filter((student) => {
        return (
          (student.studentId &&
            student.studentId
              .toString()
              .toLowerCase()
              .includes(search.value.toLowerCase())) ||
          (student.fullName &&
            student.fullName
              .toLowerCase()
              .includes(search.value.toLowerCase())) ||
          (student.email &&
            student.email.toLowerCase().includes(search.value.toLowerCase())) ||
          (student.accountType &&
            student.accountType
              .toLowerCase()
              .includes(search.value.toLowerCase())) ||
          (student.dob &&
            student.dob
              .toString()
              .toLowerCase()
              .includes(search.value.toLowerCase())) ||
          (student.state &&
            student.state.toLowerCase().includes(search.value.toLowerCase()))
        );
      })
    );

    const updateSelectedStudent = (student: IStudent) => {
      store.commit(Mutations.SET_SELECTED_STUDENT, student);
      router.push({ name: "apps-students-details" });
    };

    onBeforeMount(() => {
      store.dispatch(Actions.GET_STUDENTS);
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Students Listing", ["Apps", "Students"]);
    });

    const toggleStudentsState = (studentIds: Array<number>) => {
      studentIds.forEach((id) => {
        toggleStudentState(id);
      });
    };

    const toggleStudentState = (id) => {
      store.dispatch(Actions.TOGGLE_STUDENT_STATE, id);
    };

    return {
      displayedStudents,
      tableHeader,
      toggleStudentsState,
      toggleStudentState,
      checkedStudents,
      updateSelectedStudent,
      search,
    };
  },
  get students() {
    return this.students;
  },
});
